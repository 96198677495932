<template>
  <no-sidebar-layout v-if="isDataLoaded">
    <v-container class="px-0 pb-6">
      <v-row class="d-flex flex-column justify-center  my-0">
        <v-img :src="project.logo" max-width="200px" max-height="200px" contain class="mt-4 mb-2 rounded-circle align-self-center" />
      </v-row>
      <v-form class="form" @submit.prevent="login">
        <h2 class="text-center mt-8" v-text="$t('club.login.title')" />
        <p class="mt-4 body-2 font-weight-light text-center" v-text="$t('club.login.description')" />
        <v-text-field
          ref="password"
          v-model="form.password" :label="$t('user.fields.password')"
          :type="showPassword ? 'text' : 'password'" name="password"
          :error-messages="getFieldErrors('password')"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @blur="$v.form.password.$touch()"
        />
        <v-row>
          <v-col cols="2">
            <v-checkbox
              v-model="collaboratorUser.hasAcceptedCheckbox"
              @change="$store.dispatch('user/toggleAcceptedCheckbox', { organizationId, projectId, clubId, value: collaboratorUser.hasAcceptedCheckbox })"
            />
          </v-col>
          <v-col cols="10">
            <span class="caption font-weight-light" v-html="loginClubCheckboxText" />
          </v-col>
        </v-row>
        <v-btn
          :disabled="!(collaboratorUser.hasAcceptedCheckbox)"
          type="submit"
          class="mt-4 mb-8"
          color="primary"
          block
          depressed
          v-text="$t('user.login')"
        />
      </v-form>
    </v-container>
  </no-sidebar-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import localeUtils from '@/utils/mixins/localeUtils'

export default {
  name: 'LoginClub',
  components: {
    NoSidebarLayout: () => import('@/components/NoSidebarLayout'),
  },
  mixins: [formUtils, localeUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    clubId: { type: String, required: true },
  },
  data() {
    return {
      project: null,
      club: null,
      collaboratorUser: null,
      loginClubCheckboxText: null,
      showPassword: false,
      form: {
        password: null,
      },
      isDataLoaded: false,
    }
  },
  validations: {
    form: {
      password: { required },
    },
  },

  async created() {
    const { organizationId, projectId, clubId } = this
    this.$store.commit('loader/show')
    this.project = await this.$store.dispatch('project/read', { organizationId, projectId })
    this.club = await this.$store.dispatch('club/read', { organizationId, projectId, clubId })
    this.loginClubCheckboxText = this.project?.loginClubCheckboxText
    this.collaboratorUser = this.club.collaboratorUser
    this.isDataLoaded = true
    this.$store.commit('loader/hide')
  },
  methods: {
    login() {
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        await this.$store.dispatch('user/login', { email: this.club.collaboratorUser.email, password: this.form.password })
        this.$router.push({ name: 'redirectAfterLogin' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  max-width: 320px;
  min-width: 320px;
  margin: auto;
}

</style>
